<template>
  <div>
    <img
      :src="require('@/assets/icons/more.svg')"
      width="18px"
      data-toggle="dropdown"
      class="pointer ml-1 dropdown-toggle"
      @click.stop="show = !show"
    />
    <d-dropdown-form
      :hide="hide"
      :show="show"
      :right="true"
      :min-width="customWidth"
      :identifier="'identifier-' + item.id"
    >
      <template v-slot:dropdownbutton>
      </template>
      <template v-slot:dropdownbody>
        <div
          v-for="(btn, i) in item.actions"
          class="p-1 pl-2 pr-2"
        >
      <span
        v-if="btn !== 'pay' && btn !== 'details'"
        class="d-flex align-items-center align-content-center pt-1 pb-1 pr-1 pl-0 ml-1"
        :key="'data-table-index' + i"
        :class="isBusy === btn ? '' : 'pointer'"
        @click.stop="onActionClickIcon (btn, item)"
      >
        <i
          v-if="isBusy !== btn"
          :class="getBtnIconClass(btn)"
          class="icon-custom-size mr-2 ml-0"
        ></i>
        <i v-else class="btn-edit-size fa fa-spinner fa-spin"></i>
         <p class="simple-label-avenir"> {{ $t('general.table.' + btn) }}</p>
      </span>
          <span
            v-if="btn === 'pay'"
            :key="'data-table-pay' + i"
            class="d-flex align-items-center align-content-center pt-1 pb-1 pr-1 pl-0 ml-1"
            @click.stop="onActionClickIcon (btn, item)"
          >
        <img
          class="pointer mr-2 ml-1"
          width="16px"
          :src="require('@/assets/caisse.svg')"
        />
             <p class="simple-label-avenir"> {{ $t('general.table.' + btn) }}</p>
      </span>
          <span
            v-if="btn === 'details'"
            :key="'data-table-details' + i"
            class="d-flex align-items-center align-content-center pt-1 pb-1 pl-0 pr-3 ml-1"
            @click.stop="onActionClickIcon (btn, item)"
          >
        <img
          class="pointer mr-2"
          width="18px"
          :src="require('@/assets/details.svg')"
        />
       <p class="simple-label-avenir"> {{ $t('general.table.' + btn) }}</p>
         </span>
          <div class="border-bottom" v-if="i < item.actions.length - 1"></div>
        </div>
      </template>
    </d-dropdown-form>
  </div>
</template>

<script>
export default {
  name: 'MobileActions',
  data: () => ({
    show: false,
    hide: false,
    activatedScrollEventListener: false,
  }),
  props: {
    isBusy: {
      type: String,
      default: ''
    },
    isDeleting: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default: this,
    },
  },
  computed: {
    customWidth() {
      return '150px';
    },
    dataTableElement() {
      return document.getElementById('dataTableId').parentNode;
    }
  },
  methods: {
    showMenu() {
      this.show = !this.show;
    },
    onActionClickIcon(btn) {
      this.$emit('on:action-click', btn, this.item);
    },
    getBtnIconClass(btn) {
      switch (btn) {
        case 'show': {
          return 'btn-close-size icofont icofont-eye-alt';
        }
        case 'delete': {
          return 'btn-close-size icofont icofont-close';
        }
        case 'edit': {
          return 'btn-edit-custom-size icofont icofont-ui-edit';
        }
        case 'rar': {
          return 'btn-edit-custom-size icofont icofont-files';
        }
        case 'unrar': {
          return 'btn-edit-custom-size icofont icofont-briefcase-alt-2';
        }
        case 'history': {
          break;
        }
      }
    },
    onScroll() {
      this.hide = !this.hide;
    },
    initScrollEventListener() {
      if (!this.activatedScrollEventListener) {
        this.activatedScrollEventListener = true;
        this.dataTableElement.addEventListener("scroll", this.onScroll);
      }
    },
    removeScrollEventListener() {
      if (this.activatedScrollEventListener) {
        this.activatedScrollEventListener = false;
        this.dataTableElement.removeEventListener("scroll", this.onScroll);
      }
    }
  },
  beforeDestroy() {
    this.removeScrollEventListener();
  },
  updated() {
    this.initScrollEventListener();
  },
}
</script>

<style lang="scss" scoped>
@import "@lazy/table/_cell-actions.scss";

/deep/ .dropup, .dropright, .dropdown, .dropleft {
  position: absolute;
  margin-top: -2%;
  right: 10%;
}

.simple-label-avenir {
  text-align: left;
  font: normal normal 15px Avenir;
  letter-spacing: 0.1px;
  color: #4D4F5C;
  opacity: 1;
}
</style>
