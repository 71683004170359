<template>
  <div>
    <div
      class="d-inline-flex"
      v-for="(btn, i) in item.actions"
    >
      <span
        v-if="btn !== 'pay'"
        :key="'data-table-index' + i"
        :class="isBusy === btn ? '' : 'pointer'"
        class="icon-2x icon-custom-size"
        @click.stop="onActionClickIcon (btn, item)"
      >
        <i
          v-if="isBusy !== btn"
          :class="getBtnIconClass(btn)"
        ></i>
        <i v-else class="btn-edit-size fa fa-spinner fa-spin"></i>
      </span>
      <span
        v-if="btn === 'pay'"
        :key="'data-table-pay' + i"
        class="d-flex align-items-center align-content-center"
        @click.stop="onActionClickIcon (btn, item)"
      >
        <img
          class="pointer ml-1"
          width="16px"
          :src="require('@/assets/caisse.svg')"
        />
      </span>
      <span
        v-if="btn === 'details'"
        :key="'data-table-details' + i"
        class="d-flex align-items-center align-content-center"
        @click.stop="onActionClickIcon (btn, item)"
      >
        <img
          class="pointer ml-1"
          width="18px"
          :src="require('@/assets/details.svg')"
        />
      </span>
    </div>
  </div>
</template>
<script>
export default {
  name: 'DesktopActions',
  data: () => ({
    button:null
  }),
  props: {
    //to do show spinner after confirm click
    isBusy:{
      type:String,
      default:''
    },
    isDeleting: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default: this,
    },
  },

  methods: {
    onActionClickIcon(btn) {
      this.$emit('on:action-click', btn, this.item);
    },
    getBtnIconClass(btn) {
      switch (btn) {
        case 'show': {
          return 'btn-close-size icofont icofont-eye-alt';
        }
        case 'delete': {
          return 'btn-close-size icofont icofont-close';
        }
        case 'edit': {
          return 'btn-edit-custom-size icofont icofont-ui-edit';
        }
        case 'rar': {
          return 'btn-edit-custom-size icofont icofont-files ml-2 ';
        }
        case 'unrar': {
          return 'btn-edit-custom-size icofont icofont-briefcase-alt-2 ml-2';
        }
        case 'history': {
          break;
        }
      }
    },
  }
}
</script>
<style lang="scss" scoped>
@import "@lazy/table/_cell-actions.scss";
</style>
