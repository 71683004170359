<template>
  <Component
    :is="componentSelection"
    :item="item"
    :is-busy="isBusy"
    :is-deleting="isDeleting"
    @on:action-click="onActionClick($event)"
  />
</template>
<script>
import DesktopActions from "./DesktopActions";
import MobileActions from "./MobileActions";

export default {
  data: () => ({
    isBusy: '',
  }),
  props: {
    isDeleting: {
      type: Boolean,
      default: false,
    },
    customView: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default: this,
    },
  },
  components: {
    DesktopActions,
    MobileActions
  },
  computed: {
    innerWidth() {
      return this.$store.getters['layout/getInnerWidth'];
    },
    isDesktopActions() {
      return this.innerWidth < 700 && this.item.actions.length > 1;
    },
    componentSelection() {
      return this.isDesktopActions ? MobileActions.name : !this.customView ? DesktopActions.name : MobileActions.name;
    }
  },
  methods: {
    onActionClick(btn) {
      if (btn === 'delete') {
        if (this.isBusy === '') {
          this.$emit('on:data-table-btn:' + btn, this.item);
        }
      } else {
        this.$emit('on:data-table-btn:' + btn, this.item);
      }
    },
  },
  beforeDestroy() {
    this.isBusy = '';
  },
}
</script>
